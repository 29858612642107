import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero"
import RightCta from "../../components/cta/right-bordered/cta"
import LeftCta from "../../components/cta/left-bordered/cta"
import Savings from "../../components/savings/savings"

import ExternalLink from "../../components/externalLink/externalLink"

import desktopHero from "../../images/homecarousel/images/crystalHeart.png"
import mobileHero from "../../images/homecarousel/images/crystalHeartMobile.png"
import gradientOne from "../../images/savings-card/gradientOne.png"
import gradientThree from "../../images/savings-card/gradientThree.svg"
import gradientTwo from "../../images/savings-card/gradientTwo.png"
import gradientDark from "../../images/clinical-trial-results/gradientThree.png"

import savingsHeroMobile from "../../images/savings-card/4.2_1086x850_Mobile_gradient_2X-.png"
import savingsHero from "../../images/savings-card/4.2_1440x280_Desktop_gradient_1X.png"

import cellPhone from "../../images/savings-card/4.2_HandEmai_Icon-01.png"
import cellPhoneMobile from "../../images/savings-card/4.2_HandEmai_Icon-01.png"
import oldMan from "../../images/savings-card/4.2_550x380_Doctor_Patient_Desktop_1X.png"
import savingsCard from "../../images/savings-card/savingsCard.png"
import blinkPharma from    "../../images/getting-started/blink_pharma.png"
import blinkPharmaPhone from    "../../images/getting-started/blink_pharma_phone.png"
// blink logo
import vascepablinklogo from    "../../images/getting-started/vascepa_blinkrx_logo_ko.png"



// import {withGetScreen} from 'react-getscreen'


const footer = `
  <p class="hang footnote-10 m-t-20 m-b-10">
    <sup>&Dagger;</sup>This offer is valid only for eligible, commercially insured patients with a valid VASCEPA<sup>&reg;</sup> (icosapent ethyl) prescription.  Patients whose prescriptions are covered or reimbursable in whole or in part by Medicare (including Medicare Part D and Medicare Advantage), Medicaid, TRICARE, DOD, or any other federal or state program are not eligible for this offer. Offer may be used by eligible patients for a maximum savings of $2400 annually, for up to $160 per 1-month fill and $480 per 3-month fill. Offer only available in the United States. Void in MA and where otherwise prohibited by law or restricted by a third party. It may not be redeemed for cash. It is not transferable and may not be combined with any other savings offer or used for any other product. This is not health insurance. BlinkRx and Amarin Pharma, Inc. reserve the right to rescind, revoke, or amend this offer at any time with or without notice. It is a violation of federal law to buy, sell or counterfeit this offer. Cash-paying patients not using insurance who acknowledge and agree they cannot submit any portion of such payment for reimbursement to any insurance or other third party are alternatively eligible for a 30-day supply for $99.
  </p>
  <p class="hang footnote-10 m-b-10">
  <sup>&sect;</sup>Available to eligible patients with commercial insurance coverage only. Patients must have a valid prescription for VASCEPA<sup>&reg;</sup> (icosapent ethyl) and a prior authorization request from their insurer on file.  Subject to terms and conditions, program provides for a 30-day fill of VASCEPA at no charge to patients while their prior authorization request is being processed and is not contingent on purchase requirements of any kind. Program is not available to patients whose medications are reimbursed in whole or in part by Medicare (including Medicare Part D and Medicare Advantage), Medicaid, TRICARE, DOD or any other federal or state program. Offer subject to change or discontinuance without notice. This is not health insurance and program does not guarantee insurance coverage. No claims for payment may be submitted to any third party for product dispensed under this program. Limitations may apply.
  </p>
`;

export default () => (
  <Layout footnote={footer}>
    <SEO
      title="VASCEPA® Savings Program"
      keywords="VASCEPA savings program, VASCEPA savings card, VASCEPA copay card"
      description="With the VASCEPA® Savings Card, commercially insured patients may pay as little as $9 for 90 days*. Learn more about the VASCEPA® Savings Program. Please see Indication and Important Safety Information."
    />

    <Hero
      desktopHeroBackground={desktopHero}
      mobileHeroBackground={mobileHero}
      additionalClassName="height640"
      alt="Vascepa Glass heart"
      headline={["Two ways to save"]}
      content={[
        <span style={{ display: "inline-block" }}>
          VASCEPA is available at a lower out-of-pocket cost than generic
          icosapent ethyl for most patients.* See ways to save on added heart
          protection below.
        </span>,

        <span
          className="animationC"
          style={{
            marginTop: "15px",
            display: "inline-block",
            fontSize: "10px",
            lineHeight: "130%",
            textIndent: "-5px",
          }}
        >
          *Data based on analysis of DRG formulary data and Symphony claims data
          of patients' final out-of-pocket cost, with eligible commercially
          insured patients using the VASCEPA Savings Card. Data on file.
        </span>,
      ]}
    />
        <RightCta
      colOne="5"
      colTwo="7"
      componentID="savings-card"
      whiteborder="whiteborder"
      savingsCardImage="savingsCardImage"
      image={savingsCard}
      mobileImage={savingsCard}
      background={gradientOne}
      alt="savings card"
      imgSM="savings-card"
      mobileImageClass="vascepa-mobile-savings-card"
      headline={[
        "Eligible patients can pay as little as $9 for 90 days",
        <span class="un-bold">
          <sup>&dagger;</sup>
        </span>," with the VASCEPA Savings Card"
      ]}
      content={[
        "This could save you up to $150 per month, or $450 for a 90-day prescription. The benefit may count toward your commercial annual prescription deductible. In order to redeem this offer, you must have a valid prescription for VASCEPA and either have commercial insurance or be uninsured patients paying cash. Follow the dosage instructions given by your doctor.",
        <br />,
        <br />,
        <span
          style={{ textIndent: "-7px", display: "block", fontSize: "14px" }}
        >
          <sup>&dagger;</sup>For eligible patients only. Maximum savings and other terms and conditions apply. See{" "}
          <a
            className="dont-break-out"
            href="https://vascepa.copaysavingsprogram.com"
            target="_blank"
            style={{ color: "#fff", textDecoration: "underline" }}
          >
            https://vascepa.copaysavingsprogram.com
          </a>{" "}
          for details.
        </span>,
      ]}
      cta={["Get savings card ", <span aria-hidden="true">&gt;</span>]}
      external="true"
      url="https://vascepasavings.com"
      containerClass="container-two"
      contentClass="content-two"
    />


    <div className="block row">
      <div
        style={{ padding: "0px", minHeight: "300px" }}
        className="col-md-3 block-content whiteborderRight center-img"
      >
        <img src={blinkPharmaPhone} alt="phone in hand"></img>
      </div>

      <div
        className="col-md-9 block-content resource-block"
        style={{ "padding-bottom": "80px" }}
      >
        <img
          class="background-gradient blink-section"
          src={gradientDark}
          alt=""
        ></img>
        <div class="content" style={{ minHeight: "200px" }}>
          <img
            className="m-t-40 mobile-d-100"
            style={{
              zIndex: "1",
              position: "relative",
              margin: "auto",
              display: "inherit",
            }}
            src={vascepablinklogo}
            alt="VASCEPA nad BlinkRx logo"
          />
          <p
            className="font-margins"
            style={{
              fontSize: "22px",
              zIndex: "1",
              position: "relative",
            }}
          >
            VASCEPA is available through BlinkRx, an online prescription service that seeks
            to find the lowest price for you.
          </p>
          <ul
            className="font-margins m-b-60"
            style={{
              fontSize: "22px",
              zIndex: "1",
              position: "relative",
              paddingLeft: "20px",
            }}
          >
            <li>
              <strong>
                Pay as low as $0 for 90 days
                <sup style={{ fontWeight: "300" }}>&Dagger;</sup>{" "}
              </strong>
              for eligible, commercially insured patients
            </li>
            <li>
              <strong>
                Free 30-day fill
                <sup style={{ fontWeight: "300" }}>&sect;</sup>{" "}
              </strong>
              for patients waiting on prior authorization approval
            </li>
            <li>
              <strong>Lowest available cash price </strong>for patients not covered by
              insurance
            </li>
          </ul>
          <p
            className="font-margins mobile-mb-40"
            style={{
              fontSize: "22px",
              zIndex: "1",
              position: "relative",
            }}
          >
            Ask your doctor to send your new VASCEPA prescription to BlinkRx
          </p>
          <div class="links">
            <h3>
              <ExternalLink
                link="https://www.blinkhealth.com/vascepa"
                label="Get started today >"
                styleName="style-a1"
              />
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div
      style={{ overflow: "hidden", position: "relative", minHeight: "250px" }}
      className="mobile-550 row block"
    >
      <img
        style={{
          height: "100%",
          position: "absolute",
          right: "0px",
          margin: "auto",
        }}
        alt="Card Icon"
        src={savingsHero}
        className="gradientbackground"
      ></img>
      <img
        src={savingsHeroMobile}
        alt="Card Icon"
        className="mobileHeroBackground"
      ></img>
      <div
        style={{ marginLeft: "20px", marginTop: "25px", paddingTop: "20px" }}
        className="col-md-7 m-t-210 align-mobile-header-bottom"
      >
        <h3
          className="heroColor headerMobileHero sm-mt-100"
          style={{
            linearGradient: "160deg,#090979 50%,#020024",
            fontSize: "32px",
            color: "#1D355E",
            lineHeight: "140%",
          }}
        >
          If your doctor is not prescribing to BlinkRx, you may still be able to
          save with the VASCEPA Savings Card
        </h3>
        <p
          className="headerTwoMobileHero"
          style={{ color: "#4D4D4D", fontSize: "18px", lineHeight: "22px" }}
        >
          This card may not be redeemed for cash. Cardholders with questions,
          please call <br />
          1-855-497‑8462.
        </p>
      </div>
    </div>


    <div className="chatbot-spacer-vascepa-savings"></div>
  </Layout>
)
